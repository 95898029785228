import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import {ISbStoryData, SbBlokData, StoryblokComponent, useStoryblok} from '@storyblok/react';
import React from "react";

const useStyles = makeStyles({
    page: {
        background: 'black',
        minHeight: '100vh',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        '@media (max-width: 800px)': {
            display: 'block',
        },
    },
    wrapper: {
        padding: '0 0 0 64px',
        minHeight: '100vh',
        '@media (max-width: 800px)': {
            padding: '5px',
        },
    },
});

const slug = 'sdf';


interface SDFLandingStoryData extends ISbStoryData {
    content: {
        body: SbBlokData[];
    };
}

interface GenericPageProps {
    componentName: string;
}

const GenericPage: React.FC<GenericPageProps> = ({ componentName }) => {
    const classes = useStyles();
    const story = useStoryblok(slug, { version: 'published' });
    const registrationStory = story as SDFLandingStoryData;

    return (
        <Box className={classes.page}>
            <Box className={classes.wrapper}>
                {registrationStory?.content?.body
                    ?.filter(({ component }) => component === componentName)
                    .map((blok: SbBlokData) => {
                        return (
                            <StoryblokComponent
                                blok={blok}
                                key={blok._uid}
                            />
                        );
                    })}
            </Box>
        </Box>
    );
}

export default GenericPage;
