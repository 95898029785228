import React from 'react';
import { useCookies } from 'react-cookie';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.7)',
        zIndex: 9999, // Ensure it's on top of other content
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    banner: {
        width: '80%',
        maxWidth: '600px',
        backgroundColor: 'rgba(0,0,0,0.8)',
        color: 'white',
        padding: '15px',
        textAlign: 'center',
        borderRadius: '8px',
        '@media (max-width: 800px)': {
            padding: '10px',
            fontSize: '0.9em'
        }
    },
    message: {
        marginBottom: '10px'
    },
    link: {
        color: '#FFD700'
    },
    button: {
        all: 'unset',
        border: '1px solid white',
        padding: '10px',
        marginLeft: '10px',
        cursor: 'pointer',
        '@media (max-width: 800px)': {
            padding: '5px',
        }
    }
});


const CookieBanner = () => {
    const classes = useStyles();
    const [cookies, setCookie] = useCookies(['userAcceptedCookies']);

    if (cookies.userAcceptedCookies) {
        return null;
    }
    const handleAcceptCookies = () => {
        setCookie('userAcceptedCookies', true, { path: '/' });
    };

    const handleDeclineCookies = () => {
        setCookie('userAcceptedCookies', false, { path: '/' });
    };

    return (
        <div className={classes.overlay}>
            <div className={classes.banner}>
                <p className={classes.message}>
                    We store your registration data for event purposes. If you opt out of data storage, you'll only receive an email from us once you've successfully registered for the event.
                    <br />
                    For any concerns, such as deleting your data, please contact us at <a href="mailto:support@softwaredefinedautomation.io" className={classes.link}>support@softwaredefinedautomation.io</a>.
                </p>
                <div>
                    <button onClick={handleAcceptCookies} className={classes.button}>Accept</button>
                    <button onClick={handleDeclineCookies} className={classes.button}>Decline</button>
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;