import { Box } from '@mui/material';
import { SbBlokData, storyblokEditable } from '@storyblok/react';
import React, {useEffect} from 'react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { RichText } from './Richtext';

import { makeStyles } from '@mui/styles';
import {changeInlineStyles, extractBetweenParentheses} from "../utils";
import {useNavigate} from "react-router";
import CookieBanner from "./CookieBanner";

const useStyles = makeStyles({
    box: {
        color: 'white',
        margin: '64px 0',
        '@media (max-width: 800px)': {
            margin: '0', // Reduce margin on small screens and below
        },
    },
});

interface SDFBlokData extends SbBlokData {
    body: StoryblokRichtext;
}

interface GenericRichTextComponentProps {
    blok: SDFBlokData;
}

export const GenericRichTextComponent: React.FC<GenericRichTextComponentProps> = ({ blok }) => {
    const classes = useStyles();
    const navigate = useNavigate();

    useEffect(() => {
        changeInlineStyles();

        const linkButtons = document.querySelectorAll('span[style="color: rgb(0, 166, 125);"]');

        if (linkButtons?.length === 0) {
            return;
        }

        linkButtons.forEach(_button => {
            const button = _button as HTMLSpanElement;
            const link = extractBetweenParentheses(button.innerText)
            button.innerText = "here"
            button.style.display = 'inline-block';
            button.style.cursor = 'pointer';
            button.style.pointerEvents = 'auto';

            button?.addEventListener('click', () => {
                navigate(link);
            });
        })


    }, [blok]);

    return (
        <Box
            className={classes.box}
            {...storyblokEditable(blok)}
        >
            <RichText document={blok.body} />
            <CookieBanner />
        </Box>
    );
};
