import React from 'react';
import ReactDOM from 'react-dom/client';
import { apiPlugin, storyblokInit } from '@storyblok/react';
import './index.css';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { SDFRegistration } from './components/SDFRegister';
import {GenericRichTextComponent} from "./components/GenericRichTextComponent";
import GenericPage from "./pages/GenericPage";

const router = createHashRouter([
    {
        path: '/',
        element: <GenericPage componentName={"sdf_landing"} />,
    },
    {
        path: '/register',
        element: <GenericPage componentName={"sdf_registration"} />,
    },
    {
        path: '/sessions',
        element: <GenericPage componentName={"sdf_session"} />
    },
    {
        path: '/thank-you',
        element: <GenericPage componentName={"sdf_thank_you"} />
    }
]);

storyblokInit({
    accessToken: 'XqB39zL5HTbHjdUHSvbr2Qtt',
    use: [apiPlugin],
    components: {
        sdf_landing: GenericRichTextComponent,
        sdf_registration: SDFRegistration,
        sdf_session: GenericRichTextComponent,
        sdf_thank_you: GenericRichTextComponent
    },
    apiOptions: { region: 'eu' },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <RouterProvider router={router} />
    </React.StrictMode>,
);
