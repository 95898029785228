import { makeStyles } from '@mui/styles';
import { Box } from '@mui/material';
import { SbBlokData } from '@storyblok/react';
import React, { useEffect, useRef, useState } from 'react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { RichText } from './Richtext';
import { changeInlineStyles } from '../utils';
import CookieBanner from "./CookieBanner";
import {useCookies} from "react-cookie";
import emailjs from '@emailjs/browser';
import HubSpotForm from "./HubspotForm";

const useStyles = makeStyles({
    wrapper: {
        margin: '64px 0',
        '@media (max-width: 800px)': {
            margin: '0', // Reduce margin on small screens and below
        },
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '50px',
        color: 'white',
        '& input': {
            background: 'black',
            border: 'none',
            color: 'white',
        },
        '& label': {
            '@media (max-width: 800px)': {
                fontSize: '12px',
            },
        },
    },
    inputWrapper: {
        marginTop: '8px',
        display: 'flex',
        flexDirection: 'row',
        marginBottom: '16px',
    },
    arrow: {
        marginRight: '5px',
    },
    button: {
        all: 'unset',
        color: '#00A67D',
        cursor: 'pointer',
        '&:disabled': {
            opacity: 0.5,
            cursor: 'not-allowed',
        },
    },
});

interface SDFRegistrationBlokData extends SbBlokData {
    introduction: StoryblokRichtext;
    registration_complete: StoryblokRichtext;
}

interface SDFRegistrationProps {
    blok: SDFRegistrationBlokData;
}

export const SDFRegistration: React.FC<SDFRegistrationProps> = ({ blok }) => {
    const classes = useStyles();
    const [success, setSuccess] = useState<boolean>(false);

    useEffect(() => {
        changeInlineStyles();
    }, [success]);

    return (
        <Box className={classes.wrapper}>
            <RichText document={blok.introduction} />
            <HubSpotForm />
            <CookieBanner />
        </Box>
    );
};
