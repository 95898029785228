import React from 'react';
import { Box } from '@mui/material';
import { StoryblokRichtext, render } from 'storyblok-rich-text-react-renderer';
import { makeStyles } from '@mui/styles';

interface RichtextProps {
    document: StoryblokRichtext;
}

const useStyles = makeStyles({
    box: {
        maxWidth: '800px',
        overflow: 'hidden',
        '@media (max-width: 800px)': {
            maxWidth: '100%',
            fontSize: '12px',
            margin: '0',
        },
    },
});

export const RichText: React.FC<RichtextProps> = ({ document }) => {
    const classes = useStyles();
    // document is the rich text object you receive from Storyblok,
    // in the form { type: "doc", content: [ ... ] }
    return <Box className={`${classes.box} box`}>{render(document)}</Box>;
};
