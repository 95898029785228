import React, { useEffect } from 'react';
import "./hubspot.css"

const HubSpotForm = () => {
    useEffect(() => {
        // Check if the script is already present
        const existingScript = document.querySelector('script[src="//js-eu1.hsforms.net/forms/embed/v2.js"]');

        if (!existingScript) {
            const script = document.createElement('script');
            script.src = '//js-eu1.hsforms.net/forms/embed/v2.js';
            script.async = true;
            document.body.appendChild(script);

            script.onload = () => {
                // @ts-ignore
                if (window.hbspt) {
                    // @ts-ignore
                    window.hbspt.forms.create({
                        region: "eu1",
                        portalId: "25622188",
                        formId: "c3742e49-874b-45fd-874c-7b9b89388e66",
                        target: '#hubspotForm'
                    });
                }
            };

            return () => {
                document.body.removeChild(script);
            };
        } else {
            // If script is already present, create the form
            // @ts-ignore
            if (window.hbspt) {
                // @ts-ignore
                window.hbspt.forms.create({
                    region: "eu1",
                    portalId: "25622188",
                    formId: "c3742e49-874b-45fd-874c-7b9b89388e66",
                    target: '#hubspotForm'
                });
            }
        }
    }, []);

    return (
        <div style={{color: "white"}} id="hubspotForm"></div>
    );
};

export default HubSpotForm;
