export function changeInlineStyles() {
    // Select all paragraph and span elements in the document
    const pElements: HTMLCollectionOf<HTMLParagraphElement> = document.getElementsByTagName('p');
    const spanElements: HTMLCollectionOf<HTMLSpanElement> = document.getElementsByTagName('span');

    // Function to change color if it's 'rgb(0, 0, 0)'
    const changeColor = (element) => {
        if (element.style.color === 'rgb(0, 0, 0)') {
            // Change the color
            element.style.color = 'rgb(255, 255, 255)';
        }
    };

    // Iterate over the paragraph elements
    for (let i = 0; i < pElements.length; i++) {
        changeColor(pElements[i]);
    }

    // Iterate over the span elements
    for (let i = 0; i < spanElements.length; i++) {
        changeColor(spanElements[i]);
    }
}

export const extractBetweenParentheses = (input: string): string | null => {
    const match = input.match(/\(([^)]+)\)/);
    return match ? match[1] : null;
}